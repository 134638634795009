import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";
import { Link } from "react-scroll";

const priceboxData = [
  {
    id: 1,
    title: "Essentials",
    icon: "images/price-1.svg",
    content: {
      id: 0,
      text: "Just what you need to get your website up and running!",
      list: ["Website audit", "Beautiful custom site", "Email setup"],
    },
    price: 9,
    best: false,
    badge: false,
  },
  {
    id: 2,
    title: "Premium",
    icon: "images/price-2.svg",
    content: {
      id: 1,
      text: "Everything offerred in Essentials plus ways to increase traffic to your website!",
      list: ["Website audit", "Beautiful custom site", "Email setup", "Search Engine Optimization", "Analytics", "Live chat & support"],
    },
    price: 49,
    best: true,
    badge: "Recommended",
  },
  {
    id: 3,
    title: "Ultimate",
    icon: "images/price-3.svg",
    content: {
      id: 1,
      text: "Comprehensive eCommerce website to have your customers coming back for more!",
      list: ["Website audit", "Beautiful custom site", "Email setup", "Search Engine Optimization", "Analytics", "eCommerce", "Live chat & support", "Maintenance plan", "Site hosting"],
    },
    price: 99,
    best: false,
    badge: false,
  },
];

function Pricing() {
  return (
    <section id="prices">
      <div className="container">
        <Pagetitle title="Packages" />
        <div className="row align-items-top">
          {priceboxData.map((pricebox) => (
            <div key={pricebox.id} className="col-md-4 mr-2">
              <Pricebox priceboxItem={pricebox} />
            </div>
          ))}
        </div>
        <div className=" row mt-5 text-center">
          <p className="mb-0">
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link> to schedule a free consultation and quote!
            
  
          </p>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
