import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Collapse } from "react-bootstrap";

function Timeline({ education }) {
  const { years, title, content } = education;
  const [showMore, setShowMore] = React.useState(false);
  const firstPart = content.slice(0, 3);
  const secondPart = content.slice(3);
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="timeline-container">
        <div className="content">
          <span className="time">{years}</span>
          <h3 className="title">{title}</h3>
          <ul>
            {firstPart.map((item) => (
              <li key={item.id}>{item}</li>
            ))}
          </ul>
          <Collapse in={showMore}>
            <ul style={{ marginTop: -16 }}>
              {secondPart.map((item) => (
                <li key={item.id}>{item}</li>
              ))}
            </ul>
          </Collapse>
          {secondPart.length > 0 && (
            <Button size="sm" onClick={() => setShowMore((prev) => !prev)}>
              {showMore ? "Hide" : "Show More"}
            </Button>
          )}
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Timeline;
