import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/service-1.svg",
    title: "UI/UX design",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
    contentList: [],
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/service-2.svg",
    title: "Web Development",
    content:
      "I bring your ideas to life in the browser and deliver you a beautiful customized website for you or your business.  Some features and services I offer are:",
    contentList: ['Beautiful & mobile-friendly custom site', 'Website audits', 'SEO (Search Engine Optimization)', 'eCommerce', 'Email setup', 'Site analytics', 'Site scheduling & appointments'],
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "images/service-3.svg",
    title: "Photography",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
    contentList: [],
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  let service = servicesData[1];
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing justify-content-center">
            <div className="col-md-6" key={service.id}>
              <Service service={service} />
            </div>
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-pricing"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to learn more! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
