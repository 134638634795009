import React from "react";
import { Link } from "react-scroll";
import ReactTypingEffect from "react-typing-effect";

function Herosection(props) {
  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
    >
      <div className="container">
        <div className="intro">
          <img
            src="images/AndrewTruong.png"
            alt="Andrew Truong"
            className="profile-pic mb-4"
          />

          <h1 className="mb-2 mt-0">Andrew Truong</h1>
          <p>
            I'm a Web{" "}
            <ReactTypingEffect
              text={["Developer.", "Consultant."]}
              speed={100}
              eraseSpeed={100}
              eraseDelay={1500}
              typingDelay={500}
            />
          </p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a
                href="https://codepen.io/computerscientistandrew"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-codepen"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/andrew-truong-39088790"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://github.com/ComputerScientistAndrew?tab=repositories"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Herosection;
