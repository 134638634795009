import React from "react";

function Pricebox({ priceboxItem }) {
  const { icon, title, content, price, badge, best } = priceboxItem;
  return (
    <div className="price-item bg-white rounded shadow-dark text-center">
      <img src={icon} alt="Regular" />
      <h2 className="plan">{title}</h2>
      <p>{content.text}</p>
      <ul className="service-list">
        {content.list.map((item) => {
          return (
            <li>
              <span className="checkmark">
                <i className="far fa-check-circle"></i>
              </span>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Pricebox;
