import React, { useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import emailjs from "emailjs-com";
import { ToastContainer, Toast } from "react-bootstrap";

const {
  REACT_APP_EMAILJS_TEMPLATE_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_USER_ID,
} = process.env;

function Contact() {
  const form = useRef();
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState([]);

  const validateForm = () => {
    let errorMessage = [];
    let hasErrors = false;
    if (!formdata.name) {
      hasErrors = true;
      errorMessage.push("Name is required. ");
    }
    if (!formdata.email) {
      hasErrors = true;
      errorMessage.push("Email is required. ");
    }
    if (!formdata.subject) {
      hasErrors = true;
      errorMessage.push("Subject is required. ");
    }
    if (!formdata.message) {
      hasErrors = true;
      errorMessage.push("Message is required");
    }

    setError(hasErrors);
    setMessage(errorMessage);

    return !hasErrors;
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (validateForm()) {
      emailjs
        .sendForm(
          `${REACT_APP_EMAILJS_SERVICE_ID}`,
          `${REACT_APP_EMAILJS_TEMPLATE_ID}`,
          form.current,
          `${REACT_APP_EMAILJS_USER_ID}`
        )
        .then(
          (result) => {
            clearForm();
            setShow(true);
          },
          (error) => {
          }
        );
    }
  };

  const clearForm = () => {
    setFormdata({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    setError(false);
    setMessage([]);
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message.length > 0) {
      return (
        <div className="alert alert-danger mt-4">
          <ul>
            {message.map((error) => {
              return (
                <li>
                  <i class="fas fa-times-circle"></i>
                  {error}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <Pagetitle title="Get in Touch" />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Schedule a consultation with me or if you'd like to just talk!</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>
                  Don't like forms? Send me an{" "}
                  <a href="mailto:contactme@andrewtruong.dev">email</a>. 👋
                </p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              ref={form}
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control required"
                      name="name"
                      id="InputName"
                      placeholder="Your name"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="phone"
                      className="form-control"
                      id="InputPhone"
                      name="phone"
                      placeholder="Phone (Optional)"
                      onChange={handleChange}
                      value={formdata.phone}
                    />
                  </div>
                </div>
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Send Message
              </button>
            </form>
            {handleAlerts()}
          </div>
        </div>
        <div className="row">
          <div className="col ml-5 toast-container">
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                autohide
              >
                <Toast.Header closeButton={false}>
                <span className="m-2 mb-0"><i class="fas fa-paper-plane"></i></span>
                  <strong className="mt-2">Message sent!</strong>
                </Toast.Header>
                <Toast.Body>Thank you for reaching out to me!  I look forward to speaking with you!</Toast.Body>
              </Toast>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
