import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Andrew",
  avatarImage: "/images/AndrewTruong.png",
  content:
    "I am Andrew Truong, web developer from Houston, Texas. I have rich experience in web site building and customization.",
};

const progressData = [
  {
    id: 1,
    title: "Development",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "UI/UX Design",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Photography",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <p>
                    Checkout my work and I would love to get together to discuss
                    how I can help build the best site for you!
                  </p>
                  <div className="mt-3">
                    <a
                      href="https://drive.google.com/file/d/1-XwkRWukfO91y2RUratJ87BUynE9bSCv/view?usp=share_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-default"
                    >
                      View Resume
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  <div>
                    <h4>Location</h4>
                    <p>Houston, Texas</p>
                  </div>
                  <div>
                    <h4>Phone</h4>
                    <a href="tel: +2813059146">281-305-9146</a>
                  </div>
                  <div>
                    <h4>Email</h4>
                    <a href="mailto: contactme@andrewtruong.dev">
                      contactme@andrewtruong.dev
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
