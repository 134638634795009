import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "University of Houston - Bachelor's Degree",
    years: "2020 - 2016",
    content:
      "B.S. Degree in Computer Science at the University of Houston.  Graduated with Summa Cum Laude Honors.",
  },
  {
    id: 2,
    title: "Bachelor’s Degree",
    years: "2017 - 2013",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 3,
    title: "Honours Degree",
    years: "2013 - 2009",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
];

const experienceData = [
  {
    id: 6,
    title: "Key Lime Interactive LLC -UI Frontend Developer",
    years: "June 2022 - Present",
    content: [
      "Developer Consultant working with KLI client on a government contract",
      "Develop in React to build key software ",
      "Use React Hooks (useState, useEffect, useMemo, useCallback, and more) to manage state and optimize performance",
      "Consume GraphQL resource queries to direct to frontend components ",
      "Use SAFE methodologies to plan PIs for development ",
      "Write tests using React Testing Library to ensure code coverage and accurate user workflow ",
      "Have cross team discussions regarding dependencies, confidence votes, and sprint planning",
      "Conduct code reviews and implemented best practices to ensure software quality, maintainability, and scalability",
      "Code owner of multiple newly created smart components necessary for efficient case tracking ",
    ],
  },
  {
    id: 3,
    title: "Nucamp - Coding Bootcamp Instructor",
    years: "2021 - Present",
    content: [
      "Teaching their Full Stack Course (MERN stack) & Python Course to aspiring Developers",
      "Curriculum involved HTML, CSS, JavaScript, React, Express, Python, and SQL",
      "Total Rating of 4.87/5.00 with over 200 reviews ",
    ],
  },
  {
    id: 5,
    title: "EBSCO - Software Engineer II ",
    years: "Jan 2022 - June 2022",
    content: [
      "Updated legacy code to modern frameworks using Next.js (React) ",
      "Use advanced React tools such as hooks and react-redux to manage app state and optimize app performance",
      "Write tests using jest to ensure code coverage ",
      "Present developed features to ART teams",
      "Added functionality to remove personalization and ensure COPPA is upheld",
      "Collaborated with team during PI planning to discuss program risks, dependencies, and sprints are well planned",
    ],
  },
  {
    id: 2,
    title: "BeSmartee - Full Stack Developer",
    years: "2021 - Present",
    content: [
      "Part of the Feature Development Team where we maintain and improve features of BeSmartee’s web application ",
      "Tasked with completely updating the Frontend of the web application using Vue.JS ",
      "Implemented RESTful API endpoints with PHP to communicate with database (mySQL) and send responses and resources to the Frontend to consume",
      "Communicate and collaborate with offshore team in Vietnam for synchronizing work, code reviews, and code testing",
      "Worked with InfoSec team to improve security vulnerabilities against XXS and IDOR attacks through additional proper and effective validation and sanitation of inputs ",
    ],
  },

  {
    id: 4,
    title: "Reynolds & Reynolds - Software Developer",
    years: "2020-2021",
    content: [
      "Worked in ASP.net framework using JavaScript, SQL, C#, creating regression testing using Selenium, updating legacy code to modern standards, and Agile principles",
      "Worked with product managers to architect a Queue system for AppOne’s biggest client, improving their productivity in keeping track of loan applications at each step",
    ],
  },
  {
    id: 7,
    title: "Laser Perfect Skin - Contract Developer",
    years: "June 2019 - April 2020",
    content: [
      "Consulted with client’s Laser Treatment Clinic based in Grand Prairie, TX on building their website and improving customer base",
      "Completely replaced their previous Wix website to a fully customized and attractive NextJS (React) application",
      "Met with client at each step to discuss options and actions and ensure approval before proceeding",
      "Led a small development team as the lead developer, overseeing project progress and ensuring the timely delivery of high-quality software",
      "Used Jira project tracking software to break up project needs into user stories and assigned ",
    ],
  },
  {
    id: 8,
    title: "Dental Access Texas - Contract Developer",
    years: "Jan 2019 - June 2019",
    content: [
      "Consult with client’s Dental Clinic based in Arlington, TX on building their websites for their three clinics and improving customer base",
      "Completely replaced their previous Wix website to a fully customized and attractive React application",
      "Led a small development team as the lead developer, overseeing project progress and ensuring the timely delivery of high-quality software",
      "Used Redux for global state management to change language text throughout the application with user selection",
    ],
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-lg-8">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
